/**
 * Configuration
 * @module config
 */

const config = {
  name: 'Yield Bucks',
  description: 'Yield Bucks',
  BASE_URL: 'https://yieldbucksapi-o4sj7u5zca-uc.a.run.app/api/v2',
  FIREBASE_API_KEY: 'AIzaSyAMk0bI0EF7OQbz-mCCr7JBqKxivenyDuk',
  FIREBASE_AUTH_DOMAIN: 'yieldbucks-dev-344900.firebaseapp.com',
  FIREBASE_PROJECT_ID: 'yieldbucks-dev-344900',
  REGION: 'US',
};

export default config;
