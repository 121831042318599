import { keyMirror } from '../modules/helpers';

/**
 * @namespace Constants
 * @desc App constants
 */

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  // app
  STARTUP: undefined,
  EXCEPTION: undefined,
  SHOW_ALERT: undefined,
  HIDE_ALERT: undefined,
  // auth
  LOGIN: undefined,
  LOGIN_SUCCESS: undefined,
  LOGIN_FAILURE: undefined,
  LOGOUT: undefined,
  LOGOUT_SUCCESS: undefined,
  LOGOUT_FAILURE: undefined,
  RECOVER_PASSWORD: undefined,
  RECOVER_PASSWORD_SUCCESS: undefined,
  RECOVER_PASSWORD_FAILURE: undefined,
  RESET_PASSWORD: undefined,
  RESET_PASSWORD_SUCCESS: undefined,
  RESET_PASSWORD_FAILURE: undefined,
  UPDATE_PROFILE: undefined,
  UPDATE_PROFILE_SUCCESS: undefined,
  UPDATE_PROFILE_FAILURE: undefined,
  UPDATE_PASSWORD: undefined,
  UPDATE_PASSWORD_SUCCESS: undefined,
  UPDATE_PASSWORD_FAILURE: undefined,
  // user
  GET_USERS_REQUEST: undefined,
  GET_USERS_SUCCESS: undefined,
  GET_USERS_FAILURE: undefined,
  GET_USER_REQUEST: undefined,
  GET_USER_SUCCESS: undefined,
  GET_USER_FAILURE: undefined,
  EDIT_USER_REQUEST: undefined,
  EDIT_USER_SUCCESS: undefined,
  EDIT_USER_FAILURE: undefined,
  USER_LOGOUT: undefined,
  USER_LOGOUT_SUCCESS: undefined,
  USER_LOGOUT_FAILURE: undefined,
  GET_PROFILE: undefined,
  GET_PROFILE_SUCCESS: undefined,
  GET_PROFILE_FAILURE: undefined,
  DELETE_USER_REQUEST: undefined,
  DELETE_USER_SUCCESS: undefined,
  DELETE_USER_FAILURE: undefined,
  // notifications
  GET_NOTIFICATIONS_REQUEST: undefined,
  GET_NOTIFICATIONS_SUCCESS: undefined,
  GET_NOTIFICATIONS_FAILURE: undefined,
  CREATE_NOTIFICATION_REQUEST: undefined,
  CREATE_NOTIFICATION_SUCCESS: undefined,
  CREATE_NOTIFICATION_FAILURE: undefined,
  EDIT_NOTIFICATION_REQUEST: undefined,
  EDIT_NOTIFICATION_SUCCESS: undefined,
  EDIT_NOTIFICATION_FAILURE: undefined,
  DELETE_NOTIFICATION_REQUEST: undefined,
  DELETE_NOTIFICATION_SUCCESS: undefined,
  DELETE_NOTIFICATION_FAILURE: undefined,
  SWITCH_FORM_VISIBLE: undefined,
  // cards
  GET_CARDS_REQUEST: undefined,
  GET_CARDS_SUCCESS: undefined,
  GET_CARDS_FAILURE: undefined,
  GET_CARD_REQUEST: undefined,
  GET_CARD_SUCCESS: undefined,
  GET_CARD_FAILURE: undefined,
  CREATE_CARD_REQUEST: undefined,
  CREATE_CARD_SUCCESS: undefined,
  CREATE_CARD_FAILURE: undefined,
  EDIT_CARDS_REQUEST: undefined,
  EDIT_CARDS_SUCCESS: undefined,
  EDIT_CARDS_FAILURE: undefined,
  APPROVE_CARDS_REQUEST: undefined,
  APPROVE_CARDS_SUCCESS: undefined,
  APPROVE_CARDS_FAILURE: undefined,
  DELETE_CARD_AND_SEND_NOTIFICATION_REQUEST: undefined,
  DELETE_CARD_AND_SEND_NOTIFICATION_SUCCESS: undefined,
  DELETE_CARD_AND_SEND_NOTIFICATION_FAILURE: undefined,
  // features
  GET_NEW_FEATURES_REQUEST: undefined,
  GET_NEW_FEATURES_SUCCESS: undefined,
  GET_NEW_FEATURES_FAILURE: undefined,
  // all rewards
  GET_ALL_REWARDS_REQUEST: undefined,
  GET_ALL_REWARDS_SUCCESS: undefined,
  GET_ALL_REWARDS_FAILURE: undefined,
  // all categories
  GET_ALL_CATEGORIES_REQUEST: undefined,
  GET_ALL_CATEGORIES_SUCCESS: undefined,
  GET_ALL_CATEGORIES_FAILURE: undefined,
});

export const MessageTypes = [
  {
    label: 'Card Payment Due',
    value: 'Card Payment Due',
  },
  {
    label: 'Budget Limit Alert - Crossed',
    value: 'Budget Limit Alert - Crossed',
  },
  {
    label: 'Budget Limit Alert',
    value: 'Budget Limit Alert',
  },
  {
    label: 'Budget Limit Alert - Affiliate',
    value: 'Budget Limit Alert - Affiliate',
  },
  {
    label: 'Store - Affiliate',
    value: 'Store - Affiliate',
  },
];

export const UserStatus = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Blocked',
    value: 'Blocked',
  },
];

export const CardsStatus = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'active',
    value: 'active',
  },
  {
    label: 'pending',
    value: 'pending',
  },
];

export const MapMessageTypesKeys = {
  'Card Payment Due': ['amount', 'due_date', 'cardname'],
  'Budget Limit Alert - Crossed': ['category', 'limit_amount'],
  'Budget Limit Alert': ['category', 'limit_amount'],
  'Budget Limit Alert - Affiliate': ['category', 'limit', 'affilate_url', 'bank_name'],
  'Store - Affiliate': ['store_name', 'cashback', 'affilate', 'spend_category'],
};

export const MapMessageTypesExamples = {
  'Card Payment Due': {
    amount: '30',
    due_date: '05/10',
    cardname: 'Visa Classic',
  },
  'Budget Limit Alert - Crossed': {
    category: 'restaurant',
    limit_amount: '300',
  },
  'Budget Limit Alert': {
    category: 'travel',
    limit_amount: '400',
  },
  'Budget Limit Alert - Affiliate': {
    category: 'restaurant',
    limit: '300usd',
    affilate_url: 'Test',
    bank_name: 'Test',
  },
  'Store - Affiliate': {
    store_name: 'Starbucks',
    cashback: '10%',
    affilate: 'Affilate',
    spend_category: 'restaurant',
  },
};
