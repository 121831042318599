import React, { Component } from 'react';
import { Button, Form, Row, Col, Modal } from 'react-bootstrap';
import { Formik, FieldArray, Field } from 'formik';
import * as Yup from 'yup';
import Icon from '../../components/Icon';
import { Colors } from '../../modules/theme';

export default class CardFormModal extends Component {
  render() {
    const { show, isEditing, onClose, onSubmit, initialValues, loading } = this.props;

    return (
      <Modal show={show} onHide={onClose} className="modal-90w" centered keyboard={false}>
        <Modal.Header
          closeButton
          style={{
            padding: '25px 25px 10px 25px',
          }}
        >
          <h3 className="modal-header-title-30mrg">{`${isEditing ? 'Edit' : 'Add'} Card`}</h3>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={isEditing}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Required'),
              bankName: Yup.string().required('Required'),
            })}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isValid,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row className="g-2 p-4">
                    <Col md>
                      <Form.Group className="mb-2" controlId="title">
                        <Form.Label as="h4">Name*</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          readOnly={values.source == 'ccStack'}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name && touched.name && errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md>
                      <Form.Group className="mb-2" controlId="url">
                        <Form.Label as="h4">Url</Form.Label>
                        <Form.Control
                          type="text"
                          name="url"
                          placeholder="Url"
                          value={values.url}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.url && touched.url && errors.url}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="g-2 p-4">
                    <Col md>
                      <Form.Group className="mb-2" controlId="messageType">
                        <Form.Label as="h4">Bank Name*</Form.Label>
                        <Form.Control
                          type="text"
                          name="bankName"
                          placeholder="Type Bank’s Name here..."
                          value={values.bankName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          readOnly={values.source == 'ccStack'}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.bankName && touched.bankName && errors.bankName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md>
                      <Form.Group className="mb-2" controlId="messageType">
                        <Form.Label as="h4">Bank Address*</Form.Label>
                        <Form.Control
                          type="text"
                          name="bankAddress"
                          placeholder="Type Bank Address here..."
                          value={values.bankAddress}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          readOnly={values.source == 'ccStack'}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.bankAddress && touched.bankAddress && errors.bankAddress}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="g-2 p-4">
                    <Col md>
                      <Form.Group className="mb-2" controlId="rewards">
                        <FieldArray
                          name="rewards"
                          render={arrayHelpers => (
                            <div>
                              <Button
                                className="mb-4"
                                type="button"
                                onClick={() =>
                                  arrayHelpers.push({ id: '', title: '', source: 'admin' })
                                }
                              >
                                Add reward
                              </Button>
                              {values.rewards &&
                                values.rewards.map((reward, index) => (
                                  <div key={index}>
                                    <Row className="mb-5">
                                      <Col>
                                        <Form.Label as="h4">Title*</Form.Label>
                                        <Field
                                          className="field form-control"
                                          name={`rewards.${index}.title`}
                                          readOnly={reward.source == 'ccStack'}
                                        />
                                      </Col>

                                      {reward.source !== 'ccStack' && (
                                        <Col
                                          className="pt-5 text-center"
                                          xs={2}
                                          sm={2}
                                          md={1}
                                          ls={1}
                                        >
                                          <Icon
                                            color={Colors.warning}
                                            name="trash-alt"
                                            onClick={() => arrayHelpers.remove(index)}
                                          />
                                        </Col>
                                      )}
                                    </Row>
                                  </div>
                                ))}
                            </div>
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                    <Button variant="primary mb-5 mt-5" size="lg" type="submit">
                      {loading ? 'Loading…' : 'Submit'}
                    </Button>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
}
